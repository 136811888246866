import React from 'react';

import Layout from '@components/Layout/Layout';
import SEO from '@components/seo';

const privacy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <h1>Privacy Policy</h1>

    <i>
      Last updated: September 23, 2023<br></br>
    </i>
    <hr></hr>
    <p>
      It is Seelay's ("Seelay," "we", "our" or "us") policy to respect your
      privacy regarding any information we may collect while operating our
      website(s) and application(s).
    </p>
    <br></br>
    <p>
      This Privacy Policy describes what information we collect, how we use the
      information and how you can control what you provide us.
    </p>
    <h2>What information is collected</h2>
    <h3>
      <u>Information you provide us</u>
    </h3>
    <p>We may collect the following personally-identifying information:</p>
    <ul>
      <li>• Email address</li>
      <li>• First name and last name</li>
      <li>• Feedback or requests for support</li>
    </ul>
    <h3>
      <u>Information we collect automatically</u>
    </h3>
    <p>
      Like most website(s) and app operators, Seelay collects
      non-personally-identifying information of the sort that web browsers and
      servers typically make available, such as the browser type, language
      preference, referring site, and the date and time of each visitor request.
      Seelay’s purpose in collecting non-personally identifying information is
      to better understand how Seelay’s visitors use its website(s) and
      application(s).
    </p>
    <h3>
      <u>Cookies</u>
    </h3>
    <p>
      A cookie is a string of information that a website stores on a visitor’s
      computer, and that the visitor’s browser provides to the website each time
      the visitor returns. Seelay uses cookies to help Seelay identify and track
      visitors, their usage of Seelay website(s), and their website(s) access
      preferences. Seelay visitors who do not wish to have cookies placed on
      their computers should set their browsers to refuse cookies before using
      Seelay website(s), with the drawback that certain features of the Seelay
      website(s) may not function properly without the aid of cookies.
    </p>
    <h3>
      <u>Aggregated Statistics</u>
    </h3>
    <p>
      Seelay may collect statistics about the behavior of visitors to its
      website(s). For instance, Seelay may monitor the most popular decks on the
      Seelay website(s). Seelay may display this information publicly or provide
      it to others.
    </p>

    <h2>How information is used</h2>
    <p>
      Seelay uses information it collects to deliver Seelay' service to you and
      continuously enhance your experience. If you are a registered user of a
      Seelay website(s) and have supplied your email address, Seelay may
      occasionally send you an email to tell you about new features, solicit
      your feedback, or just keep you up to date with what’s going on with
      Seelay and our products. We primarily use our product blog to communicate
      this type of information, so we expect to keep this type of email to a
      minimum. If you send us a request (for example via a support email or via
      one of our feedback mechanisms), we reserve the right to publish it in
      order to help us clarify or respond to your request or to help us support
      other users.
    </p>

    <h2>With whom information is shared</h2>
    <p>
      Seelay uses certain third-party services to enhance your user experience,
      collect public-information about visitors and collect and process
      feedbacks securely.
      <br></br>
      <br></br>
      Seelay discloses personally-identifying information only to those of its
      contractors and affiliated organizations that need to know that
      information in order to process it on Seelay’ behalf or to provide
      services available at Seelay’ website(s), and that have agreed not to
      disclose it to others. Some of those contractors and affiliated
      organizations may be located outside of your home country. By using
      Seelay’ website(s), you consent to the transfer of such information to
      them.
      <br></br>
      <br></br>
      Additionally, Seelay may disclose personally-identifying information only
      in response to a subpoena, court order or other governmental request, or
      when Seelay believes in good faith that disclosure is reasonably necessary
      to protect the property or rights of Seelay, third parties or the public
      at large.{' '}
      <u>
        Seelay will not rent or sell personally-identifying information to
        anyone.
      </u>
    </p>
    <h3>
      <u>Business Transfers</u>
    </h3>
    <p>
      If Seelay, or substantially all of its assets, were acquired, or in the
      unlikely event that Seelay goes out of business or enters bankruptcy, user
      information would be one of the assets that is transferred or acquired by
      a third party. You acknowledge that such transfers may occur, and that any
      acquirer of Seelay may continue to use your personal information as set
      forth in this policy.
    </p>
    <h2>How you can control the information we collect</h2>

    <p>
      Seelay collects personally-identifying information only insofar as is
      necessary or appropriate to fulfill the purpose of the visitor’s
      interaction with Seelay and it's website(s). You can control which
      information you supply at any time, with the caveat that it may prevent
      you from engaging in certain website-related activities.
    </p>

    <h3>
      <u>EU personal data</u>
    </h3>
    <p>
      If you are located in the EU, United Kingdom, Lichtenstein, Norway,
      Switzerland, or Iceland, you may have additional rights under the EU
      General Data Protection Regulation (the “GDPR”) related to your personal
      data, as further described below. Seelay will be the controller of your
      personal data processed in connection with the service.
    </p>
    <h3>
      <u>Accessing, correcting, and deleting your personal data</u>
    </h3>
    <p>
      You have certain rights with respect to your personal data, and we want to
      help you review and update your information to ensure it is accurate and
      up-to-date.
      <br></br>
      <br></br>If you have created an account, you can accomplish most of the
      following by logging into your account. For more information or any
      additional requests you can contact us via{' '}
      <a href="mailto:Aman@Seelay.in">Aman@Seelay.in</a>.
    </p>
    <ul>
      <li>
        • Access: You can request more information about the personal data we
        hold about you and request a copy of such personal data.
      </li>
      <li>
        • Rectification: If you believe that any personal data we are holding
        about you is incorrect or incomplete, you can request that we correct or
        supplement such data.
      </li>
      <li>
        • Erasure: You can request that we erase some or all of your personal
        data from our systems. If you wish to erase all of your personal data,
        you can sign in and delete your user account from the account settings
        page.
      </li>
      <li>
        • Portability: You can ask for a copy of your personal data in a
        machine-readable format.
      </li>
    </ul>
    <h2>How information is protected</h2>

    <p>
      Seelay takes all measures reasonably necessary to protect against the
      unauthorized access, use, alteration or destruction of potentially
      personally-identifying and personally-identifying information. Your
      account is protected by a password you set and are responsible for keeping
      safe. When entering information into our system such as passwords or any
      information, all if this is done securely using SSL.
    </p>
    <h2>International privacy laws</h2>

    <p>
      If you are using our service from outside of the India, please be aware
      that you are sending data, including personally identifying information,
      to the India where our servers are located. That information may then be
      transferred within the India and then transferred back out of the India to
      countries outside of your residence, depending upon the type of
      information and how it is stored by us. Our collection, storage and use of
      your personal information will at all times continue to be governed by
      this Privacy Policy.
    </p>

    <h2>Children and our services</h2>

    <p>
      Our services are not directed towards children and you may not use our
      service if you are under the age of 13. You must also be old enough to
      consent for the processing of your personal data in your country. In
      certain countries, we may allow a parent or guardian to consent for you on
      your behalf.
    </p>

    <h2>How you may contact us</h2>
    <p>
      You may contact us with any inquiries or concerns with regards to this
      privacy policy here:
      <br></br> By email: <a href="mailto:Aman@Seelay.in">Aman@Seelay.in</a>
      <br></br>By visiting this page:{' '}
      <a href="https://www.seelay.in/#contact">
        https://www.seelay.in/#contact
      </a>
    </p>
    <h2>Privacy Policy Changes</h2>
    <p>
      Seelay may change its Privacy Policy from time to time, and in Seelay’
      sole discretion. Seelay encourages visitors to frequently check this page
      for any changes to its Privacy Policy. Your continued use of this site
      after any change in this Privacy Policy will constitute your acceptance of
      such change.
    </p>
    <hr></hr>
  </Layout>
);

export default privacy;
